@font-face {
	font-family: 'Gilroy'; 
	src: url(fonts/GilroyLight.ttf); 
    font-weight: 300;
}
@font-face {
	font-family: 'Gilroy'; 
	src: url(fonts/GilroyRegular.ttf); 
    font-weight: 400;
}
@font-face {
	font-family: 'Gilroy'; 
	src: url(fonts/GilroyMedium.ttf); 
    font-weight: 500;
}
@font-face {
	font-family: 'Gilroy'; 
	src: url(fonts/GilroySemibold.ttf); 
    font-weight: 600;
}
@font-face {
	font-family: 'Gilroy'; 
	src: url(fonts/GilroyBold.ttf); 
    font-weight: 700;
}
:root {
	--light-fray: #f3f3f5;
	--white: #fff;
	--blue: #0c5da4;
	--turquoise: #4fc8e6;
	--orange: #f26421;
	--black: #070707;
	--gray: #7a7a8a;
    --base-radius: 30px;
    --light-blue: #e1edfc;
}
button{
    cursor: pointer;
    border: none;
    text-align: center;
}
img{
    max-width: 100%;
}
body{
    padding: 0px;
    margin: 0px;
}
body *{
    font-family: 'Gilroy';
}
a{
    text-decoration: none;
}
.right{
    text-align: right;
}
.center{
    text-align: center;
}
.left{
    text-align: left;
}
.left *{
    text-align: left;
}
.content{
    width: 1400px;
    max-width: calc(100% - 10px);
    padding: 0px 20px;
    margin: 0px auto;
}
.grid{
    height: 100%;
    display: grid;
    vertical-align: middle;
    align-items: center;
}
.block-title{
    position: relative;
    font-weight: 400;
    font-size: 60px;
    color: var(--black);
    margin-top: 0px;
    margin-bottom: 35px;
}
.block-title span{
    position: absolute;
    right: 0;
    top: calc(50% - 20px);
    cursor: pointer;
    border: 1px solid var(--orange);
    color: var(--orange);
    height: 40px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 20px 10px 20px;
}
.block-title span svg{
    margin-left: 10px;
}
.flex-box{
    display: flex;
}
.orange-button{
    background: var(--orange);
    font-size: 16px;
    text-align: center;
    border-radius: 50px;
    line-height: 1.6;
    padding: 10px 20px;
    color: var(--light-fray);
}
.light-button{
    background: none;
    text-align: center;
    display: block;
    border: 1px solid var(--orange);
    border-radius: var(--base-radius);
    color: var(--orange);
    padding: 10px 20px 10px 20px;
}
.light-button svg{
    margin-left: 4px;
    stroke: #F26421 !important;
    position: relative;
    top: 2px;
}
.light-button:hover{
    background-color: var(--orange);
    color: white;
}
.light-button:hover svg{
    stroke: #fff !important;
}
.max-paragraf{
    margin-top: 0px;
    margin-bottom: 45px;
    opacity: 0.7;
    font-weight: 400;
    font-size: 24px;
    line-height: 137%;
}
.mini-paragraf{
    margin: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 144%;
    color: var(--gray);
}
.paragraf *,
.paragraf{
    font-size: 18px;
    line-height: 165%;
    margin-bottom: 0px;
}
.item-title{
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 26px;
}
.base-input{
    border: none;
    padding: 23px 15px 20px 15px;
    border-bottom: 1px solid #E8E8E8;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    color: var(--black);
    width: 100%;
}
.slick-track{
    display: flex !important;
}
.slick-slide{
    height: inherit !important;
}
.cslider .slick-slide>div>div,
.cslider .slick-slide>div{
    height: 100%;
}

@media(max-width: 990px){
    .block-title{
        font-size: 38px !important;
        line-height: 1.2 !important;
    }
}
@media(max-width: 767px){
    .block-title{
        font-size: 32px !important;
        line-height: 1.2 !important;
    } 
    .block-title span{
        float: none !important;
        position: relative;
        display: block;
        margin-top: 10px;
        width: auto;
    }
    .max-paragraf{
        font-size: 18px;
    }
}

/* ------------------------------------- */
header .content{
    margin-top: 30px;
    background: var(--light-fray);
    padding: 20px;
    border-radius: var(--base-radius);
}
.contact-wrapper{
    margin: 0px;
    line-height: 1.4;
}
.header-email{
    background-image: url('images/mail.svg');
    background-position: left center;
    padding-left: 22px;
    background-repeat: no-repeat;
    color: var(--gray);
}
.header-phone{
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    color: var(--black);
}
.header-search input{
    width: 100%;
    padding: 15px;
    padding-left: 60px;
    border-radius: var(--base-radius);
    font-size: 16px;
    border: none;
}  
.header-search button{
    position: absolute;
    border-radius: var(--base-radius);
    height: 49px;
    width: 49px;
    background-color: var(--blue);
}
.header-search button img{
    margin-top: 5px;
}
.header-buttons__item{
    margin-right: 18px;
    position: relative;
}
.header-buttons__item .cart-count{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: var(--orange);
    color: var(--white);
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    left: 13px;
    top: 5px;
}
.header-buttons__item-image{
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 30px;
    font-weight: 500;
    color: var(--black);
}  
header .orange-button{
    margin-right: 30px;
    margin-left: 10px;
}

@media(max-width: 1400px){
    .header-email{
        font-size: 13px;
    }
    .header-phone{
        font-size: 14px;
    }
    .header-buttons__item{
        margin-right: 10px;
    }
    .header-buttons__item-image{
        font-size: 15px;
        padding-left: 25px;
        background-size: contain;
    }
    header .orange-button{
        margin-right: 13px;
        margin-left: 10px;
        padding: 10px 10px;
    }
}
@media(max-width: 1199px){
    header .flex-box{
        margin: 0px auto !important;
        margin-top: 15px !important;
    }
    .header-email{
        font-size: 16px;
    }
    .header-phone{
        font-size: 15px;
    }
}
@media(max-width: 990px){
    header .content{
        max-width: calc(100% - 20px);
        margin-left: 10px;
        margin-right: 10px;
    }
}
@media(max-width: 767px){
    .header-search__wrapper{
        margin-top: 20px;
        margin-bottom: 5px;
    }
    .header-buttons__item-image span{
        display: none;
    }
    .header-buttons__item-image{
        background-position: center;
        height: 20px;
    }
    header .orange-button{
        width: 100%;
        display: block;
    }
}

/* --------------------------------------- */ 
.banner{
    margin-top: 50px;
    margin-bottom: 70px;
}
.banner__wrapper{
    position: relative;
}
.banner__content-wrapper{
    position: absolute;
    top: 50%;
    width: 700px;
    left: 10px;
    z-index: 2;
    transform: translate(0px, -50%);
}
.banner__content-title{
    font-weight: 400;
    font-size: 45px;
    margin-top: 0px;
    margin-bottom: 20px;
    z-index: 2;
}
.banner__content-description{
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 18px;
    line-height: 144%;
    color: var(--black);
    z-index: 2;
}
.banner__content .orange-button{
    display: inline-block;
    margin-top: 20px;
    padding: 17px 45px;
}
.banner__image-wrapper{
    width: 750px;
    margin-left: auto;
}
.banner__image{
    height: 425px;
    background-position: center;
    background-size: cover;
    border-radius: var(--base-radius);
}
.banner__content{
    padding: 40px 30px;
    border-radius: var(--base-radius);
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    background: #fff;
    position: relative;
    overflow: hidden;
}
.banner__content::before{
    content: "";
    background-image: url('/src/images/flower-hero-card.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 39.6rem;
    height: 40rem;
    bottom: -16rem;
    right: -21rem;
    z-index: 0;
}
.banner-background-ring{
    width: 300px;
    height: 300px;
    z-index: -1;
    top: calc(50% - 150px);
    left: 30px;
    border: 1px solid #dae7f2;
    border-radius: 50%;
    position: absolute;
}
.banner-background-ring::after, .banner-background-ring::before {
    content: "";
    border-radius: 50%;
    width: 0.8rem;
    height: 0.8rem;
    position: absolute;
}
.banner-background-ring::before {
    background: #f36421;
    top: 30px;
    left: 44px;
}
.banner-background-ring::after {
    background: #0d5da4;
    top: 284px;
    left: 95px;
}

@media(max-width: 1200px){
    .banner__content-wrapper{
        width: 500px;
    }
    .banner__image-wrapper{
        width: 600px;
    }
}
@media(max-width: 990px){
    .banner__content-wrapper{
        left: 0;
    }
    .banner__content{
        box-shadow: none;
    }
    .banner__content-wrapper{
        position: relative;
        top: 0;
        width: 100%;
        transform: translate(0px, 0px);
    }
    .banner__image-wrapper{
        display: none;
        margin: 0px auto;
        margin-top: 20px;
    }
}
@media(max-width: 767px){ 
    .banner{
        margin-top: 30px;
    }
    .banner__content-title{
        font-size: 30px;
    }
}

/* --------------------------------------- */
.notification-block{
    border: 1px solid #0C5DA4;
    padding: 35px 35px 30px 35px;
    border-radius: var(--base-radius);
    margin-top: 45px;
}
.notification-block p{
    margin: 0px;
    font-size: 20px;
    line-height: 150%; 
}
.notification-block .orange-button{
    display: block;
    padding: 17px 45px;
}

@media(max-width: 990px){
    .notification-block p{
        margin-bottom: 20px;
    }
}

/* --------------------------------------- */ 
.courses-item{
    margin-left: 12px;
    margin-right: 12px;
    position: relative;
    height: 100%;
    background-color: var(--light-fray);
    padding-bottom: 135px;
    border-radius: var(--base-radius);
} 
.courses-item__wrapper{
    position: absolute;
    width: calc(100% - 0px);
    bottom: 0px;
}
.courses-image{
    border-radius: var(--base-radius);
    padding-top: 60%;
    background-position: center;
    background-size: cover;
    display: block;
}
.courses-item button{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    background-color: white;
}
.courses-description__wrapper{
    padding: 20px 30px 10px 30px;
}
.courses-price{
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 15px 30px 10px 30px;
}
.courses-item__price-info{
    line-height: 26px;
    color: var(--gray);
}
.courses-item__cost{
    font-size: 26px;
    text-align: right;
}
.courses-item__button{
    padding: 15px 30px 15px 30px;
}
.courses-item__button-time{
    background-image: url('images/times.svg');
    background-repeat: no-repeat;
    padding-left: 28px;
    font-weight: 500;
    line-height: 1.2;
}
.courses-item__button-time span{
    color: var(--gray);
}

@media(max-width: 991px){
    .light-button svg{
        display: none;
    }
}
@media(max-width: 767px){
    .courses-item__button-time{
        margin-bottom: 10px;
    }
    .courses-item{
        position: relative;
        padding-bottom: 10px;
    }
    .courses-item__wrapper{
        position: relative;
    }
    .courses-item__cost{
        text-align: left;
    }
}

/* --------------------------------------- */ 
.about-project{
    margin-top: 80px;
}
.about-project__wrapper{
    border-radius: var(--base-radius);
    background-color: var(--light-fray);
    padding: 50px 40px;
} 
.about-project iframe{
    width: 400px;
    max-width: 100%;
    height: 550px;
    border-radius: var(--base-radius);
    border: none;
    display: block;
    margin: 0px auto;
}
.about-project .slick-arrow.slick-prev{
    left: 25px;
}
.about-project .slick-arrow.slick-next{
    right: 25px;
}

@media(max-width: 767px){
    .about-project iframe{
        height: 400px;
    }
    .about-project__wrapper{
        padding: 30px 20px;
    }
}

/* --------------------------------------- */
.advantages{
    padding-top: 100px;
    padding-bottom: 100px;
}
.advantages-item__wrapper{
    height: 100%;
    background-color: var(--light-fray);
    border-radius: var(--base-radius);   
    padding: 30px;
}
.advantages .row>div{
    margin-bottom: 20px;
}
.advantages-item__wrapper .item-title{
    margin-top: 20px;
    margin-bottom: 10px;
}
@media(max-width: 767px){
    .advantages{
        padding-top: 65px;
        padding-bottom: 65px;
    }   
}

/* --------------------------------------- */
.reviews .content{
    padding-top: 0px;
    padding-bottom: 100px;
}
.reviews-item__wrapper{
    background-color: var(--light-fray);
    border-radius: var(--base-radius);
    height: 100%;
    padding: 30px;
}
.reviews-item__fio-wrapper{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.reviews-item__img{
    width: 100px;
}
.reviews-item__img>div{
    width: 80px;
    height: 80px;
    margin-right: 20px;
}
.reviews-item__fio{
    width: calc(100% - 100px);
    font-size: 22px;
}
.reviews-item__fio span a,
.reviews-item__fio span{
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    color: var(--blue);
} 
@media(max-width: 767px){
    .reviews-item__wrapper{
        margin-bottom: 20px;
    }
    .reviews .row>.col-md-6:first-child{
        margin-bottom: 25px;
    }
    .reviews .content{
        padding-bottom: 50px;
    }
}

/* --------------------------------------- */
.partners{
    margin-bottom: 80px;
}
.partners-wrapper{
    margin-left: 10px;
    margin-right: 10px;
    background-color: var(--light-fray);
    border-radius: 30px;
    height: 150px;
}
.partners-wrapper>.grid{
    padding: 10px;
}
.partners-wrapper img{
    margin: auto;
}
@media(max-width: 760px){
    .partners-wrapper img{
        max-height: 130px;
    }
}

/* --------------------------------------- */
.corparative-partners__wrapper{
    padding: 40px 45px;
    background-color: var(--light-blue);
    border-radius: var(--base-radius);
    background-position: center right 50px;
    background-repeat: no-repeat;
}
.corparative-partners__wrapper .block-title{
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 60px;
}
.corparative-partners__wrapper .paragraf{ 
    color: var(--gray);
}
.corparative-partners__wrapper .orange-button{
    padding: 17px 45px;
}

@media(max-width: 991px){
    .corparative-partners__wrapper{
        padding: 35px 20px;
    } 
    .corparative-partners__wrapper .orange-button{
        margin-top: 15px;
        display: inline-block;
    }
}

/* --------------------------------------- */
.questions .content{
    padding-top: 80px;
    padding-bottom: 100px;
}
.questions-wrapper{
    background-color: var(--light-fray);
    border-radius: var(--base-radius);
    padding: 30px;
}  
.questions .block-title{
    line-height: 47%;
    margin-bottom: 60px;
    margin-top: 35px;
}
.questions-list{
    background-color: var(--white);
    border-radius: var(--base-radius);
}
.questions-item{
    position: relative;
    cursor: pointer;
    padding: 35px 25px 35px 85px;
    border-bottom: 1px solid var(--light-fray);
}
.questions-item .pls{
    cursor: pointer;
    background-image: url('images/qw-arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 25px;
    left: 25px;
    transition: 0.65s ease;
}
.questions-item.active .pls{
    transform: rotate(-180deg);
    transition: 0.65s ease;
}
.questions-item__title{
    font-weight: 400;
    font-size: 20px;
    color: var(--black);
}
.questions-item__description{
    padding-top: 30px;
    display: none;
} 
.questions-item.active .questions-item__description{
    display: block;
}

/* --------------------------------------- */
.questions-form{
    margin-top: 40px;
    background-color: var(--blue);
    border-radius: var(--base-radius);
    padding: 45px;
}
.questions-form__title{
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 500;
    font-size: 26px;
    line-height: 115%;
    color: var(--white);
}
.questions-form__description{
    font-weight: 400;
    font-size: 18px;
    color: var(--white);
    opacity: 0.7;
    margin-top: 5px;
}
.questions-form input{
    width: 100%;
    font-size: 16px;
    border: none;
    line-height: 32px;
    border-radius: 10px;
    padding: 15px;
    color: var(--gray);
}
.questions-form .orange-button{
    border-radius: var(--base-radius);
    padding: 17px 45px;
    width: 100%;
}
.questions-form__confirmed-text{
    color: white;
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 0px; 
}

@media(max-width: 990px){
    .questions-form__title{
        font-size: 36px;
        margin-bottom: 15px;
        margin-top: 10px;
        line-height: 1.15;
    } 
    .questions-form input{
        margin-bottom: 10px;
    }
}

/* --------------------------------------- */
footer{
    background-color: var(--light-fray);
    border-radius: 30px 30px 0px 0px;
}
footer .content{
    padding-top: 80px;
    padding-bottom: 50px;
}
footer>.content, .footer-underline .content{
    padding-top: 40px;
    padding-bottom: 40px;
}
.footer-menu h3{
    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
    color: var(--blue);
    margin: 0px;
    opacity: 0.7;
}
.footer-menu ul{
    margin-top: 20px;
    padding: 0px;
    list-style: none;
}
.footer-menu ul a{
    color: var(--black);
}
.footer-menu ul li{
    margin-bottom: 15px;
}
.footer-social{
    margin-top: 20px;
}
.footer-social a img{
    margin-right: 5px;
}
.popup-subscribe{
    text-decoration: underline;
    margin-top: 25px;
    text-decoration-skip-ink: none;
    color: var(--orange);
}
.footer-partner img,
.footer-partner{
    margin-top: 15px;
}
.footer-partner img{
    width: 135px;
}

@media(max-width: 767px){
    .footer-partner{
        margin-top: 0px;
    }
    footer .right{
        text-align: left !important;
    }
    footer .content{
        padding-top: 40px;
    }  
    .footer-partner,
    .footer-underline__text{
        margin-bottom: 20px;
    }
}

/* --------------------------------------- */
.footer-underline{
    background-color: var(--blue);
}
.footer-underline a{
    line-height: 144%;
    color: var(--white);
} 
.footer-underline__text{
    color: var(--white);
    line-height: 1.4;
}
.footer-underline__offer{
    color: var(--white);
    margin-top: 20px;
}

/* --------------------------------------- */
.popup-form{
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}
.popup-form>div{
    background-color: var(--white);
    padding: 20px 20px 40px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: var(--base-radius);
    width: 500px;
    max-width: 100%;
}
.popup-form input{
    border: none;
    padding: 23px 15px 20px 15px;
    border-bottom: 1px solid #E8E8E8;
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 20px;
    color: var(--black);
    width: 100%;
}
.popup-form button{
    padding: 15px 50px;
    display: block;
    margin-top: 25px;
}
.popup-form__description{
    margin-top: 12px;
    margin-bottom: 25px;
}

/* --------------------------------------- */
.loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background-color: white;
}
.loader img{
    width: 80px;
    margin-bottom: -5px;
    animation-name: rotate;
    animation-duration: 25s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-radius: 50%;
}
.loader>div{
    width: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0px;
    font-size: 20px;
    line-height: 165%;
}
@keyframes rotate{
    from{ transform: rotate(-360deg); }
    to{ transform: rotate(360deg); }
}

/* --------------------------------------- */
.catalog .content{
    padding-top: 100px;
    padding-bottom: 100px;
}
.catalog .margin{
margin-bottom: 35px;
}
.catalog .courses-item{
    margin: 0px;
}

/* --------------------------------------- */
.pagination{
    margin-top: 20px;
}
.pagination>div{
    margin: 0px 3px;
}
.pagination__item{
    display: inline-block;
    border: 1px solid #868686;
    border-radius: 10px;
    overflow: hidden;
}
.pagination__item.dots{ 
    padding: 5px 10px;
    min-width: 20px;
    color: var(--black);
}
.pagination__item>*{
    padding: 7px 10px 4px 10px;
    min-width: 20px;
    color: var(--black);
    display: block;
}
.pagination__item.active span,
.pagination__item:hover span{
    color: var(--white);
}
.pagination__item.active span,
.pagination__item span:hover{
    cursor: pointer;
    color: var(--white);
    background-color: rgba(0, 119, 255, 1);
}

/* --------------------------------------- */
.favourites .content{
    padding-top: 80px;
    padding-bottom: 80px;
}
.favourites-list{
    margin-top: 55px;
}
.favourites .courses-item{
    margin: 0px;
}
.favourites .margin{
    margin-bottom: 20px;
}

/* --------------------------------------- */
.account-type{
    margin-bottom: 30px;
}
.account-type .light-button{
    display: inline-block;
    padding: 17px 70px;
    font-size: 16px;
}
.account-type .light-button:hover,
.account-type .light-button.active{
    background: var(--orange);
    color: var(--light-fray);
}

/* --------------------------------------- */
.acccount .content{
    padding-top: 80px;
    padding-bottom: 80px;
}
.acccount-email-information{
    font-size: 18;
    padding: 10px 0px;
    margin-top: 0;
    margin-bottom: 40px;
}
.acccount .courses-item{
    margin: 0px;
}
.acccount .col-md-6{
    margin-bottom: 20px;
}

@media(max-width: 767px){
    .acccount .courses-item{
        padding-bottom: 0px !important;
    }
}

/* --------------------------------------- */
.account-menu__item{
    display: block;
    background-color: var(--light-fray);
    border-radius: var(--base-radius);
    font-size: 25px;
    color: var(--black);
    margin-bottom: 20px;
}
.account-menu__item>*{
    padding: 30px 30px 30px 120px;
    background-position: left 25px center;
    background-repeat: no-repeat;
}
.account-menu__item.active,
.account-menu__item:hover{
    color: var(--white);
    cursor: pointer;
    background-image: url(images/bggrad.svg);
    background-position: center;
    background-size: cover;
}
.account01:hover>div{
    background-image: url('./images/menu_01.png') !important; 
}
.account02:hover>div{
    background-image: url('./images/menu_02.png') !important;
}
.account03:hover>div{
    background-image: url('./images/menu_03.png') !important;
}
.account04:hover>div{
    background-image: url('./images/menu_04.png') !important;
}
.account05:hover>div{
    background-image: url('./images/menu_05.png') !important;
}

/* --------------------------------------- */
.account-form__block{
    margin-top: 70px;
} 
.account-form__block .orange-button{
    padding: 17px 70px;
    margin-top: 35px;
}

/* --------------------------------------- */
.popup-notification{
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}
.popup-notification>div{
    background-color: white;
    padding: 20px 20px 40px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    width: 500px;
    max-width: 100%;
    max-height: 98vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.popup-notification__close{
    text-align: right;
}
.popup-notification__close img{
    width: 40px;
    cursor: pointer;
}
.popup-test__item{
    border-top: 1px solid rgba(0,0,0,0.2);
    padding: 10px 0px;
}
.popup-test__item p{
    margin: 0px;
}

/* --------------------------------------- */
.course-banner .content{
    padding-top: 70px;
}
.course-banner__description{
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 165%;
}
.course-banner__icons{
    font-weight: 400;
    font-size: 16px;
    line-height: 131%;
    color: var(--gray);   
}
.course-banner__icons span{
    margin-bottom: 3px;
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 144%;
    color: var(--black);
}
.course-banner__icons-wrapper{
    padding-left: 60px;
    max-width: 350px;
    padding-right: 50px;
    background-position: left top;
    background-repeat: no-repeat;
}
.course-banner__icons{
    max-width: 100%;
    width: 650px;
}
.course-banner__icons>div{
    display: inline-block;
    vertical-align: top;
}
.course-banner__buttons{
    margin-top: 40px;
}
.course-banner__buttons .orange-button{
    line-height: 70px;
    width: 100%;
    padding: 0px 50px;
}
.course-banner__buttons .grid a{
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 144%;
    color: var(--blue);
}
.course-banner__button-like{
    background: none;
    border-radius: 50%;
    width: 70px;
    border: 1px solid var(--gray);
    padding: 0px 5px;
    margin-left: 10px;
}
.course-banner__button-like svg{
    position: relative;
}
.course-banner__button-like.active,
.course-banner__button-like:hover{
    border: 1px solid var(--orange);
    background-color: var(--orange);
}
.course-banner__button-like.active svg path,
.course-banner__button-like:hover svg path{
    fill: var(--white);
}

@media(max-width: 1060px){ 
    .course-banner img{
        display: none;
    }
}
@media(max-width: 767px){
    .course-banner__button-like{
        margin: 0px auto;
        margin-top: 15px;
    }
    .course-banner__icons{
        width: 100%;
    }
}

/* --------------------------------------- */
.course-about .content{
    padding-top: 75px;
} 
.course-about .paragraf a{
    font-weight: 400;
    font-size: 16px;
    line-height: 144%;
    color: var(--blue);
    text-decoration: none;
}

@media(max-width: 767px){
    .course-about .content{
        padding-top: 50px;
    }
}

/* --------------------------------------- */
.take-care{
    margin-top: 100px;
}
.take-care__wrapper{
    max-width: 100%;
    margin: 0px auto;
    background-color: var(--light-blue);
    padding: 50px 80px;
    border-radius: var(--base-radius);
    background-image: url('images/take-care.svg');
    background-position: top right;
    background-repeat: no-repeat;
}
.take-care .block-title{
    margin-bottom: 0px;
}
.take-care .paragraf{
    color: var(--gray);
}

@media(max-width: 767px){
    .take-care__wrapper{
        padding: 30px;
        background-size: 550px;
        background-position: top -120px right -120px;
    }
}

/* --------------------------------------- */
.what-learn .content{
    padding-top: 100px;
    padding-bottom: 80px;
}
.what-learn__item{
    padding: 30px 30px 30px 120px;
    margin-bottom: 30px;
    border-radius: var(--base-radius);
    background-color: var(--light-fray);
    background-position: left 30px center;
    background-repeat: no-repeat;
    height: calc(100% - 30px);
    min-height: 105px;
}

@media(max-width: 767px){
    .what-learn .content{
        padding-bottom: 50px;
        padding-top: 70px;
    }
}

/* --------------------------------------- */
.account-help{
    background-color: var(--light-fray);
    border-radius: var(--base-radius);
    padding: 85px 50px;
}
.account-help__telegram-link{
    text-decoration: none;
    background-image: url(images/bggrad.svg);
    background-size: cover;
    display: block;
    border-radius: var(--base-radius);
}
.account-help__telegram-link>div{
    background-image: url(images/tgblock.svg);
    background-repeat: no-repeat;
    background-position: right 20px center;
    font-weight: 500;
    font-size: 25px;
    color: var(--white);
    padding: 20px 70px 20px 40px;
}
.account-help__contact a{
    color: var(--orange);
}
.account-help__contact .paragraf{
    margin-bottom: 15px;
}

@media(max-width: 767px){
    .account-help__contact{
        margin-top: 25px;
    }
    .account-help{
        padding: 30px 30px;
    }
}

/* --------------------------------------- */
.how-training{
    background-color: var(--light-blue);
    padding-top: 70px;
    padding-bottom: 70px;
} 
.how-training__staps{
    margin-top: 70px;
}
.how-training__staps-number{
    color: var(--blue);
    margin-bottom: 13px;
}
.how-training__staps .item-title{
    margin-top: 20px;
}
.how-training__staps img,
.how-training__staps{
    position: relative;
}
.how-training__staps>div::before{
    z-index: 0;
    content: " ";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 55px;
    background-color: rgba(0,0,0, 0.3);
}
.how-training__staps>div:last-child:before{
    display: none;
}

@media(max-width: 767px){
    .how-training__staps{
        margin-top: 35px;
    }
    .how-training__staps .item-title{
        margin-top: 5px;
    }
    .how-training__staps>div{
        padding-top: 15px;
        padding-bottom: 25px;
    }
    .how-training__staps>div::before{
        display: none;
    }
    .how-training__staps-number{
        position: absolute;
        top: 30px;
        padding-left: 60px;
    }
}

/* --------------------------------------- */
.course.our-courses .content{
    padding-top: 20px;
    padding-bottom: 100px;
}

/* --------------------------------------- */
.certificates .content{
    padding-top: 20px;
    padding-bottom: 100px;
}
.certificates__wrapper{
    background-color: var(--light-fray);
    border-radius: var(--base-radius);
    padding: 70px 70px 100px 385px;
    position: relative;
}
.certificates__wrapper img{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: -290px;
    width: 650px;
}
.certificates__wrapper .block-title{
    margin-bottom: 30px;
}

@media(max-width: 990px){
    .certificates__wrapper{
        padding-left: 200px;
    }
    .certificates__wrapper img{
        left: -300px;
        width: 500px;
    }
}
@media(max-width: 767px){
    .certificates .content{
        padding-bottom: 50px;
    }
    .certificates__wrapper img{
        left: 50%;
        top: -135px;
        max-width: 100%;
        width: 450px;
        transform: translate(-50%);
    }
    .certificates__wrapper{
        padding: 40px;
        padding-top: 220px;
        text-align: center;
    }
}
@media(max-width: 500px){
    .certificates__wrapper{
        padding: 40px;
        padding-top: 160px;
    }
}

/* --------------------------------------- */
.course-teacher .content{
    padding-top: 100px;
    padding-bottom: 100px;
}
.course-teacher__description{
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 20px;
}
.course-teacher__wrapper{
    margin-left: 10px;
    margin-right: 10px;
    background-color: var(--light-fray);
    height: 100%;
    border-radius: 30px;
    overflow: hidden;
}
.course-teacher__image{
    height: 300px;
    background-position: center;
    background-size: cover;
}
.course-teacher__content{
    padding: 30px;
}
.course-teacher__content .mini-paragraf{
    margin-top: 0px;
    margin-bottom: 20px;
}

@media(max-width: 767px){
    .course-teacher .content{
        padding-top: 60px;
    }
}

/* --------------------------------------- */
.course-order-info{
    background-color: var(--light-fray);
    margin-bottom: 120px;
    margin-top: 100px;
}
.course-order-info .content{
    padding-top: 100px;
    padding-bottom: 100px;
}
.course-order-info__wrapper{
    background-color: var(--white);
    padding: 30px;
    border-radius: var(--base-radius);
}
.course-order-info .block-title{
    margin-top: 0px;
    margin-bottom: 25px;
}
.course-order-info__discount-header{
    position: relative;
    background-color: var(--blue);
    padding: 20px;
    color: var(--white);
}
.course-order-info__discount-header img{
    position: absolute;
    top: 8px;
    margin-left: -50px;
}
.course-order-info__discount{
    background-color: var(--light-fray);
    background-image: url('images/buyinfo-bg.svg');
    border-radius: var(--base-radius);
    background-position: left bottom;
    background-repeat: no-repeat;
    overflow: hidden;
}
.course-order-info__discount-amount{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 400;
    font-size: 60px;
    color: var(--blue);
}
.course-order-info__discount-amount span{
    display: block;
    font-weight: 400;
    font-size: 26px;
    line-height: 115%;
    text-decoration: line-through;
    color: var(--black);
}
.course-order-info__pack{
    margin-bottom: 30px;
}
.course-order-info__icons{
    padding-bottom: 12px;
    padding-top: 5px;
}
.course-order-info .orange-button{ 
    line-height: 70px;
    padding: 0px 50px;
    margin-top: 20px;
    color: var(--light-fray);
}

@media(max-width: 767px){
    .course-order-info .row>div{
        margin-top: 30px;
    }
}

/* --------------------------------------- */
.course-module__list{
    background-color: var(--white);
    border-radius: var(--base-radius);
}
.course-module__item.active .questions-item__description{
    display: block;
}
.course-module__item button{
    font-weight: 500;
    font-size: 16px;
}
.course-module__buy-info>*{
    display: inline-block;
    margin-left: 12px;
}
.course-module__buy-info svg{
    margin-top: 10px;
}
.course-module__wrapper{
    background-color: var(--light-fray);
    border-radius: 30px;
    padding: 40px;
}

@media(max-width: 990px){
    .course-module__wrapper{
        padding: 25px 15px;
    }
}
@media(max-width: 767px){
    .course-module__buy-info{
        text-align: left;
        display: block;
        margin-top: 10px;
    }
    .course-module__buy-info>*{
        margin-left: 0px;
        margin-bottom: 10px;
        display: block;
    }
}

/* --------------------------------------- */
.form-login .content{
    padding-top: 75px;
    padding-bottom: 75px;
}
.form .orange-button{
    margin-top: 30px;
    padding: 10px 40px;
    margin-right: 20px;
}
.form a{
    color: var(--blue);
}
.form{
    background-color: var(--light-fray);
    padding: 30px 30px 30px 30px;
    width: 600px;
    max-width: 100%;
    margin: 0px auto;
    border-radius: var(--base-radius);
    background-image: url('images/take-care.svg');
    background-position: right top;
    background-repeat: no-repeat;
}
.form form{
    background-color: var(--white);
    border-radius: var(--base-radius);
    padding: 20px 20px 20px 20px;
}
.form h2{
    font-size: 26px;
    margin-bottom: 20px;
}
.form .paragraf{
    margin-bottom: 20px;
}

@media(max-width: 767px){
    .dop-span{
        display: block;
        margin-top: 20px;
    }
}

/* --------------------------------------- */
.show-password{
    position: relative;
}
.show-password input{
    padding-right: 40px !important;
}
.show-password img{
    z-index: 2;
    cursor: pointer;
    width: 24px;
    height: 24px;
    right: 5px;
    top: calc(50% - 12px);
    position: absolute;
}

/* --------------------------------------- */
.order .content{
    padding-top: 80px;
    padding-bottom: 80px;
}
.order-return{
    padding-left: 40px;
    background-position: left center;
    background-repeat: no-repeat;
    display: block;
    width: 130px;
    font-weight: 500;
    color: var(--blue);
}
.order .light-button{
    margin-right: 0px !important;
    margin-left: auto !important;
    border-color: var(--blue);
    font-size: 17px;
    color: var(--blue);
}
.order .light-button:hover{
    color: var(--white);
    background-color: var(--blue);
}
.order-item__list{
    margin-top: 40px;
}
.order-item__list .order-item:nth-child(1){
    border-top: 1px solid var(--light-fray);
}
.order-item{
    border-bottom: 1px solid var(--light-fray);
    padding: 25px 30px;
}
.order-item__image{
    padding-top: 100px;
    border-radius: 20px;
    background-position: center;
    background-size: cover;
} 
.order-item__category{
    margin: 0px;
}
.order-item__category a{
    color: var(--blue);
    text-decoration: none;
}
.order-item__title{
    margin-bottom: 0px;
}
.order-item__price span{
    font-size: 16px;
    text-decoration: line-through;
    text-align: right;
    color: var(--blue);
    margin-right: 10px;
}
.order-item__price p{
    margin: 0px;
    font-size: 26px;
    text-align: right;
}
.order-item__price button{
    background-color: rgba(0,0,0,0);
    background-image: url(images/del.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
}
.order-item__price button:hover{
    background-image: url(images/del_active.png);
}

@media(max-width: 767px){
    .order .light-button{
        margin-left: 0px !important;
        margin-right: auto !important;
        margin-top: 25px;
    }
    .order-item__image{
        padding-top: 200px;
        margin-bottom: 20px;
    }
    .order-item__price p{
        margin-top: 15px;
        text-align: left;
    }
    .order-item__price .right{
        text-align: left;
    }
}

/* --------------------------------------- */
.order-promo__block{
    margin-top: 80px;
} 
.order-promo__block .orange-button{
    margin-top: 30px;
    padding: 15px 50px;
}

/* --------------------------------------- */
.order__amount-information{
    position: sticky;
    top: 5px;
    background-color: var(--light-fray);
    border-radius: var(--base-radius);
    padding: 30px 20px 20px 20px;
}
.order__amount-information .item-title{
    font-weight: 600;
    font-size: 32px;
}
.order__amount-information .mini-paragraf{
    margin-bottom: 17px;
}
.order__amount-information .orange-button{
    margin-top: 10px;
    padding: 15px;
    width: 100%;
}
@media(max-width: 990px){
    .order__amount-information{
        margin-bottom: 30px;
    }
}

/* --------------------------------------- */
.purchased-course .content{
    padding-top: 80px;
    padding-bottom: 80px;
}
.purchased-course__descscription .text-center,
.purchased-course__descscription *{
    text-align: left !important;
} 
.purchased-course__complite{
    background-color: var(--orange);
    padding: 20px 30px 17px 30px;
    font-size: 20px;
    color: var(--white);
    border-radius: var(--base-radius);
}

/* --------------------------------------- */
.purchased-course__modules{
    margin-top: 45px;
    background-color: var(--light-fray);
    padding: 20px 35px;
    border-radius: var(--base-radius);
}
.purchased-course__modules .item-title{
    margin-top: 10px;
    margin-bottom: 30px;
}
.purchased-course__modules .item-title a{
    color: var(--blue);
}
.purchased-course__modules .item-title span{
    margin-left: 15px;
    font-size: 18px;
}
.purchased-course__wrapper{
    border-radius: var(--base-radius);
    background-color: var(--white);
}

/* --------------------------------------- */
.course-module__title-module{
    font-weight: 500;
    font-size: 16px;
    color: var(--blue);
    display: block;
}

/* --------------------------------------- */
.loader-block img{
    width: 80px;
    animation-name: rotate;
    animation-duration: 25s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    border-radius: 50%;
}
.loader-block{
    margin-bottom: -30px;
    text-align: center;
}
.loader-block p{
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 20px;
    line-height: 165%;
    color: var(--black);
}

/* --------------------------------------- */
.lesson-item .content{
    padding-top: 80px;
    padding-bottom: 80px;
}
.lesson-item__content *{
    line-height: 144%;
}
.lesson-item__content iframe,
.lesson-item__content video{
    max-width: 100%;
}
.lesson-item__content li,
.lesson-item__content p{
    margin-top: 0px; 
}
@media(max-width: 767px){
    .lesson-item__content iframe,
    .lesson-item__content video{
        max-width: 100%;
        height: 250px;
    }
    
}


/* --------------------------------------- */
.lesson-item__buttons{
    margin-top: 25px;
    background-color: var(--light-fray);
    border-radius: var(--base-radius);
    padding: 20px;
}
.lesson-item__buttons .light-button{
    display: inline-block;
    margin-top: 0px;
    margin-left: 15px;
    border: 2px solid var(--orange);
} 

/* --------------------------------------- */
.popup-review{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100vh;
}
.popup-review>div{
    width: 790px;
    max-width: 98%;
    background-color: var(--white);
    border-radius: var(--base-radius);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px rgba(0,0,0, 0.1);
    padding: 30px;
}
.popup-review__header{
    font-weight: 400;
    font-size: 26px;
    color: var(--black);
}
.popup-review__header svg{
    float: right;
    cursor: pointer;
    position: relative;
    top: -5px;
}
.popup-review__content{
    height: calc(100vh - 170px);
    overflow-x: hidden;
    margin-top: 25px;
}
.popup-review__content .reviews-item__wrapper{
    margin-bottom: 25px;
}

/* --------------------------------------- */
.popup-info__close{
    text-align: right;
}
.popup-info__close img{
    width: 40px;
}

/* ------------------------------------------------ */
.lesson-item__testing{
    margin-top: 30px;
    background-color: var(--light-fray);
    border-radius: var(--base-radius);
    padding: 20px;
}
.lesson-item__testing table{
    overflow: hidden;
    background-color: rgba(255,255,255,0.5);
    border-radius: var(--base-radius);
    width: 100%; 
    border: 0px; 
    border-collapse: collapse;
}
.lesson-item__testing table td{
    border-right: 1px solid rgba(0,0,0,0.1);
}
.lesson-item__testing table thead th:last-child,
.lesson-item__testing table tr td:last-child{
    border-right: 0px solid black;
}
.lesson-item__testing table thead th{
    font-weight: 500;
    border-right: 1px solid rgba(0,0,0,0.1);
    padding: 10px;
}
.lesson-item__testing table td {
    padding: 0.6rem 1rem; 
    border-bottom: 1px solid #e8e9eb;
}

/* --------------------------------------- */
.popup-tests__step-number{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 10px;
}
.popup-tests__step-content{
    border-bottom: 1px solid rgba(0,0,0,0.1);   
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.popup-tests__step-questions{
    border: 1px solid rgba(0,0,0, 0.1);
    padding: 15px;
}
.popup-tests__step-questions>span{
    font-size: 17px;
    margin-bottom: 10px;
    display: block;
    font-weight: 500;
}
.popup-tests__step-checkbox{
    padding-left: 30px;
}
.popup-tests__step-checkbox{
    background-image: url('images/checkbox.svg');
    background-size: 22px;
    background-position: left 2px top;
    background-repeat: no-repeat;
    margin-bottom: 15px;
    cursor: pointer;
}
.popup-tests__step-checkbox.active{
    background-image: url('images/checkbox-active.svg');
}

/* ------------------------------------------------ */
.popup-notification .light-button{
    display: block;
    margin: 0px auto;
    margin-top: 30px;
    width: 265px;
}

/* ------------------------------------------------ */
.popup-tests{
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}
.popup-tests>div{
    max-height: 98%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
    padding: 20px 20px 40px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: var(--base-radius);
    width: 900px;
    max-width: 98%;
} 
.popup-tests__step-button button{
    font-size: 16px;
    display: inline-block;
    margin-left: 15px;
}

/* --------------------------------------- */
.popup-tests__step-select{
    margin-bottom: 15px;
}
.popup-tests__step-select select{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    font-size: 16px;
    border-radius: 5px;
    padding: 10px 5px;
}

/* --------------------------------------- */
.add-to__natification{
    background-color: var(--orange);
    color: var(--white);
    position: fixed;
    top: 20px;
    border-radius: var(--base-radius);
    padding: 15px 15px;
    width: 300px;
    font-size: 15px;
    text-align: center;
    line-height: 1.5;
    opacity: 0;
    right: -400px;
    transition: 0.65s ease;
}
.add-to__natification.active{
    opacity: 1;
    right: 20px;
    transition: 0.65s ease;
}
